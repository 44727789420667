<script setup lang="ts">
const localePath = useLocalePath()
const { t } = useI18n()

const baseFilter = getBaseFilter(true)

const { data: categoriesData } = await useLazyAsyncData(() =>
  createProductsRepository().getAllCategories({
    filter: APIFilters.makeFilter(baseFilter),
    sort: APIFilters.makeSort({ weight: 'ASC', nid: 'ASC' }),
  })
)

const categories = computed(() => categoriesData.value?.items ?? [])
</script>

<template>
  <UPopover
    class="hidden items-baseline lg:flex"
    :ui="{
      width: 'w-full left-0 !-ml-[8px] rounded-0',
      shadow: '',
      ring: '',
      rounded: '',
    }"
    mode="hover"
    :close-delay="300"
  >
    <NuxtLink
      :to="localePath({ name: 'products' })"
      class="no-underline transition duration-300"
    >
      <div class="group font-bold transition duration-300 xl:text-lg">
        <div class="flex items-center">
          <span class="whitespace-nowrap">
            {{ t('base.products.products') }}
          </span>
          <Icon name="heroicons:chevron-down-20-solid" class="pl-1" />
        </div>
        <span
          class="-mt-1 block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover:max-w-full"
          :class="{
            '!max-w-full': $route.path.includes(
              localePath({ name: 'products' })
            ),
          }"
        />
      </div>
    </NuxtLink>
    <template #panel>
      <UiProductCategoryMenu :categories="categories" />
    </template>
  </UPopover>
  <div class="w-full lg:hidden">
    <NuxtLink
      :to="localePath({ name: 'products' })"
      class="font-bold no-underline transition duration-300 lg:mb-1"
    >
      <div
        class="group my-auto w-max font-bold transition duration-300 lg:text-lg"
      >
        {{ t('base.products.productLines') }}
        <span
          class="block h-[1px] max-w-0 bg-black transition-all duration-500 group-hover:max-w-full"
          :class="{
            '!max-w-full': $route.path.includes(
              localePath({ name: 'products' })
            ),
          }"
        />
      </div>
    </NuxtLink>
    <UiProductCategoryMenu small :categories="categories" />
  </div>
</template>

<style scoped></style>
